.container--grey {
  padding: 0;
  margin: 0;
  background-color: $background-backdrop;
}

.container--config {
  max-width: initial;
  padding: 0;

  @include screen-size('medium') {
    padding: 0 $spacing-md;
  }

  @include screen-size('custom', 1260px) {
    padding: 0 $spacing-lg;
  }
}

.container--config-image {
  position: relative;
  z-index: 45;
  display: grid;
  height: auto;
  padding: 0;
  aspect-ratio: 3 / 2;
  background-color: $background-backdrop;

  @include screen-size('medium') {
    display: none;
  }

  &.no-padding {
    z-index: 1000;
    padding: 0;
    margin: 0;
  }
}

.config-page__config {
  background-color: $white;

  @include screen-size('medium') {
    background-color: $background-backdrop;
  }
}

.config-page__header-links,
.config-page__spotlight {
  width: 100%;

  @include screen-size('medium') {
    display: none;
  }
}

.container--config-page {
  ~ footer,
  ~ aside {
    display: none;
  }
}

.config-page__title {
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  padding: $spacing-sm 0 $spacing-md;
  margin: 0 auto;
  background-color: $white;
  gap: $spacing-sm;

  @include screen-size('medium') {
    display: none;
    flex-direction: row;
    align-items: center;
    padding: 0 $spacing-md;
    background-color: $background-backdrop;
    gap: 0;
  }

  @include screen-size('custom', 1260px) {
    padding: 0 $spacing-xl;
  }

  &.config-page-ad__title {
    padding: $spacing-md 0;
    gap: 0.25rem;
  }
}

.config-page__content {
  position: relative;
  display: none;

  @include screen-size('medium') {
    display: flex;
    flex-direction: column;
  }

  &.interior-view {
    z-index: 980;
  }
}

.config-page__vehicle-config {
  padding-bottom: 4rem;

  @include screen-size('medium') {
    display: grid;
    padding-bottom: 7.5rem;
    gap: $spacing-xs;
    grid-auto-columns: 1fr 25rem;
    grid-auto-flow: column;
  }

  @include screen-size('large') {
    grid-auto-columns: 1fr 22.5rem;
    grid-auto-flow: column;
  }

  @include screen-size('extra-large') {
    gap: $spacing-md;
    grid-auto-columns: 1fr 27.5rem;
    grid-auto-flow: column;
  }

  @include screen-size('custom', 2560px) {
    grid-auto-columns: 1fr 35rem;
  }

  &.config-page_with-toolbar {
    padding-bottom: 8.5rem;
  }
}

.config-page__vehicle-view--sticky {
  position: sticky;
  top: 13rem;
}

.config-page__vehicle-view {
  z-index: 0;
  height: calc(100dvh - 20rem);
}

.config-page__sticky-title {
  z-index: 5;
}

.config-page__sticky-button {
  position: absolute;
  z-index: 1030;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  width: calc(100dvw - 2rem);
  flex-direction: row-reverse;
  justify-content: space-between;

  @include screen-size('medium') {
    position: fixed;
    right: auto;
    bottom: 6.5625rem;
    left: 3rem;
    width: auto;
    justify-content: flex-start;
    padding: 0;
  }

  &.config-page__sticky-button--right {
    justify-content: flex-end;
  }

  &[data-is-selector-open='true'] {
    z-index: 1020;
  }

  button[data-has-icon='true'] {
    box-shadow: 0 0.5rem 2rem 0 rgb(0 0 0 / 8%);
  }
}

.container--config-faqs {
  z-index: 10;
  padding: 5rem 0 6.5rem;
  background-color: $grey-900;

  .config-page__faqs {
    max-width: 100%;
    padding: 0 1.5rem;
    margin: 0 auto;

    @include screen-size('medium') {
      max-width: 752px;
      padding: 0;
    }

    h2 {
      @include display-sm;
      @include text-normal;
      @include font-primary;

      max-width: 240px;
      margin-right: auto;
      margin-bottom: 2rem;
      margin-left: auto;
      color: $white;
      text-align: center;

      @include screen-size('medium') {
        @include display-xl;

        max-width: 464px;
        margin-bottom: 4.75rem;
      }
    }

    .accordion__item {
      border-bottom: 1px solid $grey-800;

      &.accordion__item--last {
        border-bottom: 1px solid $grey-900;
      }
    }

    .accordion__button {
      @include display-xs;
      @include text-normal;
      @include font-primary;

      display: flex;
      justify-content: space-between;
      padding: 1.5rem 0;
      background-color: $grey-900;
      color: $white;
    }

    .accordion__panel {
      @include text-lg;
      @include text-normal;
      @include font-secondary;

      padding: 0 0 1.5rem;
      color: $white;

      a {
        @include text-normal;
        @include font-secondary;
      }
    }

    .accordion__button::before,
    .accordion__button[aria-selected='true']::before {
      display: none;
      width: 0;
      height: 0;
      border: 0;
      margin: 0;
    }

    .accordion__button::after {
      display: inline-block;
      content: '+';
      font-size: 1.5rem;
    }

    .accordion__button[aria-expanded='true']::after,
    .accordion__button[aria-selected='true']::after {
      display: inline-block;
      content: '-';
      font-size: 1.5rem;
    }
  }
}
